import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { ModalButton } from "../components/UI/Buttons"

const Document = styled.div `
  margin: 1em;
`

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
      <Document>
        <div>
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <ModalButton styleName="footer black" linkRef="/">Agree & Close</ModalButton>
      </Document>
    )
  }
  export const pageQuery = graphql`
    query($id: String!) {
      markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
          slug
          title
        }
      }
    }
  `